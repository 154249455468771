<template>
  <div class="station-games-management">
    <ContentBlock
      class="deployementmanagementblock"
      v-for="(location, i) in locationsWithAtLeastOneStation"
      :key="location.id"
    >
      <template #instructions v-if="i === 0">
        <h5>{{ $t('Enable Content on your Stations') }}</h5>
        <p
          v-if="gameType === 'cds'"
        >{{ $t('When you enable “One-Click” titles on your stations, it will automatically download and install on your computers when you save this page and your station isn’t actively being used. Once the download is complete the content will be available in the Launcher.') }}</p>
        <p
          v-else-if="gameType === 'steam' && game.ownership.freeDownload"
        >{{ $t('In order to play this content in the Launcher, use the toggle to enable the title on your desired station and make sure it is installed on your computer. Use your own license or request a Steam Key if needed. If you want to remove this title from a station, disable it using the toggle and manually uninstall it.') }}</p>
        <p
          v-else
        >{{ $t('In order to launch this title on a station, you’ll need to use your own license, enable the title on your desired station using the toggle, and ensure it has been installed on your computer. If you want to remove this title from a station, disable it using the toggle and manually uninstall it.') }}</p>
        <i18n tag="p" path="Learn more about {type} titles by reading our {link}.">
          <template #type>
            <template v-if="gameType === 'cds'">{{ $t('One-Click') }}</template>
            <template
              v-else-if="gameType === 'steam' && game.ownership.freeDownload"
            >{{ $t('Steam Key') }}</template>
            <template
              v-else-if="gameType === 'steam' && !game.ownership.freeDownload"
            >{{ $t('Buy on Steam') }}</template>
            <template v-else>{{ $t('Manually Added') }}</template>
          </template>
          <template #link>
            <UIButton
              link
              target="_blank"
              rel="nopenner"
              href="https://svr.li/k/214"
            >{{ $t('Content Activation Guide') }}</UIButton>
          </template>
        </i18n>
      </template>
      <div class="f-body">
        <div class="location">
          <UISwitch
            class="locationswitch"
            :title="$t('Enable on all the stations in {name}', { name: location.title })"
            :value="getLocationEnabled(location.id)"
            @click="e => onLocationSwitchClick(location, e)"
          >
            <template
              #additional
              v-if="gameType === 'steam' && game.ownership.freeDownload && getStationGamesWithoutKeyWithinLocation(location).length"
            >
              <UIButton
                link
                class="requestkeybtn tl"
                @click="requestGameKeysForLocation(location)"
              >{{ $t('Request Steam Keys for entire location') }}</UIButton>
            </template>
          </UISwitch>
        </div>
        <div
          class="experiencemanagement pt5 mt5 bt b--geyser"
          v-for="experience in experiencesWithStationsByLocation[location.id]"
          :key="experience.id"
        >
          <UISwitch
            class="experienceswitch mb5"
            :title="$t('Enable on all the stations in {name}', { name: experience.title })"
            :value="getExperienceEnabled(experience.id)"
            @click="e => onExperienceSwitchClick(experience, e)"
          >
            <template
              #additional
              v-if="gameType === 'steam' && game.ownership.freeDownload && getStationGamesWithoutKeyWithinExperience(experience).length"
            >
              <UIButton
                link
                class="requestkeybtn"
                @click="requestGameKeysForExperience(experience)"
              >{{ $t('Request Steam Keys for entire Experience') }}</UIButton>
            </template>
          </UISwitch>
          <div class="switchgrid">
            <template v-for="station in stationsByExperience[experience.id]">
              <ContentStationManagementSwitches
                :key="station.id"
                :title="station.title"
                :stationGame="stationGamesByStation[station.id]"
                :gameType="gameType"
                @installStationGame="installStationGame"
                @uninstallStationGame="uninstallStationGame"
                @requestKeyForStationGame="requestKeyForStationGame"
                @enableStationGame="enableStationGame"
                @disableStationGame="disableStationGame"
              />
            </template>
          </div>
        </div>
      </div>
    </ContentBlock>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';
import ContentStationManagementSwitches from '@/shared/content-station-management/ContentStationManagementSwitches';

@Component({
  components: {
    ContentBlock,
    UISwitch,
    UIButton,
    ContentStationManagementSwitches,
  },
  props: {
    game: { required: true },
    gameType: { required: true },
  },
})
export default class StationGamesManagementOperator extends Vue {
  get getNamespacedGetter () {
    return val => this.$store.getters[`stationManagement:${this.game.id}/${val}`];
  }

  get locationsWithAtLeastOneStation () {
    return this.getNamespacedGetter('locationsWithAtLeastOneStation');
  }

  get experiencesWithStationsByLocation () {
    return this.getNamespacedGetter('experiencesWithStationsByLocation');
  }

  get stationsByExperience () {
    return this.getNamespacedGetter('stationsByExperience');
  }

  get stationGamesByStation () {
    return this.getNamespacedGetter('stationGamesByStation');
  }

  getLocationEnabled (id) {
    const experiences = this.experiencesWithStationsByLocation[id];
    if (!experiences) return true;
    return experiences.every(({ id }) => this.getExperienceEnabled(id));
  }

  getExperienceEnabled (id) {
    const stations = this.stationsByExperience[id];
    return stations.every(station => this.stationGamesByStation[station.id] && this.stationGamesByStation[station.id].enabled);
  }

  installStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/installStationGame`, stationGame);
  }

  async requestGameKeysForLocation (location) {
    await this.$store.dispatch(`stationManagement:${this.game.id}/requestGameKeysForLocation`, location);

    const somePending = this.experiencesWithStationsByLocation[location.id].some(experience =>
      this.stationsByExperience[experience.id].some(station =>
        this.stationGamesByStation[station.id].key != null && this.stationGamesByStation[station.id].key.key === 'Pending'));

    if (somePending) {
      this.showKeyRequestPendingMessage();
    }
  }

  async requestGameKeysForExperience (experience) {
    await this.$store.dispatch(`stationManagement:${this.game.id}/requestGameKeysForExperience`, experience);

    const somePending = this.stationsByExperience[experience.id].some(station =>
      this.stationGamesByStation[station.id].key != null && this.stationGamesByStation[station.id].key.key === 'Pending');

    if (somePending) {
      this.showKeyRequestPendingMessage();
    }
  }

  async requestKeyForStationGame (stationGame) {
    await this.$store.dispatch(`stationManagement:${this.game.id}/requestGameKey`, stationGame);
    if (stationGame.key != null && stationGame.key.key === 'Pending') {
      this.showKeyRequestPendingMessage(false);
    }
  }

  showKeyRequestPendingMessage (plural = true) {
    const starter = plural ? 'There are currently no keys available for this title' : 'There are currently no keys available for these titles';
    const message = starter + `, an automated email has been sent to the Content Creator. 
    <br> When more keys are uploaded, one will be assigned automatically. 
    <br> Unfortunately, we are unable to provide any estimate on how long this could take`;
    this.$store.commit('setFlash', {
      type: 'info',
      message: this.$t(message),
    });
  }

  uninstallStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/uninstallStationGame`, stationGame);
  }

  disableStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/disableStationGame`, stationGame);
  }

  enableStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/enableStationGame`, stationGame);
  }

  getAllStationGamesForExperience (experience) {
    return this.getNamespacedGetter('getAllStationGamesForExperience')(experience);
  }

  getAllStationGamesForLocation (location) {
    return this.getNamespacedGetter('getAllStationGamesForLocation')(location);
  }

  getStationGamesWithoutKeyWithinLocation (location) {
    return this.getAllStationGamesForLocation(location).filter(({ key }) => !key);
  }

  getStationGamesWithoutKeyWithinExperience (experience) {
    return this.getAllStationGamesForExperience(experience).filter(({ key }) => !key);
  }

  onExperienceSwitchClick (experience, e) {
    const val = e.target.checked;
    const nonCDSEnabledMsg = this.$t('Are you sure you want to enable this content for your entire experience? Please make sure it is installed on your computers.');
    const CDSEnabledMsg = this.$t('Are you sure you want to enable this content for your entire experience? It will be installed and enabled on all computers assigned to this experience after you save the content page.');
    if (!val) {
      if (this.gameType !== 'cds') {
        this.$store.dispatch(`stationManagement:${this.game.id}/disableAllStationGamesForExperience`, experience);
      } else {
        e.preventDefault();
        this.$store.commit('setConfirm', {
          show: true,
          message: this.$t('If you would like to keep the content installed on all the computers in your experience but disabled on your stations, select “Disable Only”. To uninstall the content, click on “Disable & Uninstall”.'),
          icon: 'error',
          buttons: [
            {
              name: this.$t('Disable Only'),
              type: 'ghost',
              action: () => {
                e.target.checked = false; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
                this.$store.dispatch(`stationManagement:${this.game.id}/disableAllStationGamesForExperience`, experience);
              },
            },
            {
              name: this.$t('Disable & Uninstall'),
              action: () => {
                e.target.checked = false; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
                this.$store.dispatch(`stationManagement:${this.game.id}/uninstallAllStationGamesForExperience`, experience);
              },
            },
          ],
        });
      }
    } else {
      e.preventDefault();
      const action = this.gameType === 'cds' ? 'installAllStationGamesForExperience' : 'enableAllStationGamesForExperience';
      this.$store.commit('setConfirm', {
        show: true,
        message: this.gameType === 'cds' ? CDSEnabledMsg : nonCDSEnabledMsg,
        icon: 'check-circle',
        buttons: [
          {
            name: this.$t('Cancel'),
            type: 'cancel',
          },
          {
            name: this.$t('Yes, Enable'),
            action: () => {
              e.target.checked = true; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
              this.$store.dispatch(`stationManagement:${this.game.id}/${action}`, experience);
            },
          },
        ],
      });
    }
  }

  onLocationSwitchClick (location, e) {
    const val = e.target.checked;
    const nonCDSEnabledMsg = this.$t('Are you sure you want to enable this content for your entire location? Please make sure it is installed on your computers.');
    const CDSEnabledMsg = this.$t('Are you sure you want to enable this content for your entire location? It will be installed and enabled on all stations once you save.');
    if (!val) {
      if (this.gameType !== 'cds') {
        this.$store.dispatch(`stationManagement:${this.game.id}/disableAllStationGamesForLocation`, location);
      } else {
        e.preventDefault();
        this.$store.commit('setConfirm', {
          show: true,
          message: this.$t('If you would like to keep the content installed on all the computers at your location but disabled on your stations, select “Disable Only”. To uninstall the content, click on “Disable & Uninstall”.'),
          icon: 'error',
          buttons: [
            {
              name: this.$t('Disable Only'),
              type: 'ghost',
              action: () => {
                e.target.checked = false; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
                this.$store.dispatch(`stationManagement:${this.game.id}/disableAllStationGamesForLocation`, location);
              },
            },
            {
              name: this.$t('Disable & Uninstall'),
              action: () => {
                e.target.checked = false; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
                this.$store.dispatch(`stationManagement:${this.game.id}/uninstallAllStationGamesForLocation`, location);
              },
            },
          ],
        });
      }
    } else {
      e.preventDefault();
      const action = this.gameType === 'cds' ? 'installAllStationGamesForLocation' : 'enableAllStationGamesForLocation';
      this.$store.commit('setConfirm', {
        show: true,
        message: this.gameType === 'cds' ? CDSEnabledMsg : nonCDSEnabledMsg,
        icon: 'check-circle',
        buttons: [
          {
            name: this.$t('Cancel'),
            type: 'cancel',
          },
          {
            name: this.$t('Yes, Enable'),
            action: () => {
              e.target.checked = true; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
              this.$store.dispatch(`stationManagement:${this.game.id}/${action}`, location);
            },
          },
        ],
      });
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.station-games-management {
  & .switchgrid {
    display: grid;
    grid-template-columns: 1fr;
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
    grid-gap: var(--spacingSm);
  }
  & .requestkeybtn {
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--colorInfoFlat);
  }
}
</style>
